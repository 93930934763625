/* src/components/Quiz.css */
.answer-button {
    list-style-type: none;
    cursor: pointer;
    border: 2px solid white;
    background-color: transparent;
    border-radius: 13px;
    margin-bottom: 20px;
    width: 400px;
    font-family:'din-pro-light';
    color: #fff;
    font-size:20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    text-transform: uppercase;

  }
  
  .answer-button.selected {
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
  }
  
  .correct {
    background-color: #27ae5f8e;
    border: 2px solid rgba(255, 255, 255, 0.413);
  }
  
  .incorrect {
    background-color: #e74d3c87;
    border: 2px solid rgba(255, 255, 255, 0.413);

  }

  .quiz-content{
    margin-top: 6.5%;
    margin-left: 13%;
  }

  .question-index{
    font-size: 60px;
    color: #fff;
    font-family:'din-pro-regular';
    text-transform: uppercase;
  }

  .question-list{
    padding-inline-start: 0px;
    margin-top: 0;
  }

  .question-title{
    margin-top: -3pc;
    font-size: 40px;
    color: #fff;
    width: 70%;
    font-family:'din-pro-light';
    /* text-transform: uppercase; */
    margin-bottom: 50px;
  }

  .confirm-button{
    margin-top: 50px;
    cursor: pointer;
    border: 2px solid white;
    background-color: transparent;
    border-radius: 50px;
    height: 50px;
    font-family:'din-pro-regular';
    color: #fff;
    font-size:20px;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
  }

  
  .confirm-button:disabled{
    opacity: .4;
  }

  .empty{
    opacity: 0;
    font-size:40px;
  }

  .correct-text{
    font-family:'din-pro-medium';
    color: #27ae5f;
    font-size:40px;
  }

  .incorrect-text{
    font-family:'din-pro-medium';
    color: #e74d3c;
    font-size:40px;
  }

  .hint-button{
    margin-left: 30px;
    border: 0px;
    background-color: #00B7F1;
    font-family:'din-pro-light';

  }

  .replay-button{
    border: 0px;
    background-color: #00B7F1;
    font-family:'din-pro-light';

  }
  .question-hint-container{
    display:flex;
  }

  .hint-container{
    top:0;
    width: 25pc;
    height: 15pc;
    left: 0;
    border-radius: 13px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.162);
    margin-left:10pc;
    text-transform: uppercase;
  }
    
  .hint{
    margin-left: 20px;
    margin-right: 20px;
    text-align:center;
    font-family:'din-pro-light';
    color: #fff;
    margin-top: 25px;
    font-size:20px;
  }

  .hint-title{
    font-family:'din-pro-medium';  
    margin-top: 30px;
  }
