/* src/App.css */

@font-face {
  font-family: din-pro-regular;
  src: url("/public/assets/fonts/din-regular.otf") format("opentype");
}

@font-face {
  font-family: din-pro-light;
  src: url("/public/assets/fonts/din-light.otf") format("opentype");
}

@font-face {
  font-family: din-pro-medium;
  src: url("/public/assets/fonts/din-medium.otf") format("opentype");
}

/* Define main rules for the whole page */
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Ensure no overflow behavior */
}

/* Header style for the top quarter of the screen */
.header {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed; /* Make the header fixed in its position */
  top: 0; /* Position it at the top of the viewport */
  width: 100%; /* Take the full width of the viewport */
  z-index: 1; /* Ensure it's displayed above other content */
}

/* Center style for the two following quarters */
.center {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;/*
  padding-top: 25%; /* Adjust as needed to account for the fixed header */
}

/* Footer style for the bottom quarter of the screen */
.footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
/* Style the video element for the background */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire viewport */
  z-index: -2; /* Place the video behind other content */
}

/* Style for the transition video */
.transition-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire viewport */
  z-index: -1; /* Ensure the video is displayed above other elements */
}

/* Style the centered button */
.start-app-button {
  padding: 20px 45px;
  background-color: transparent;
  color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color:#ffffff;
  font-size: 30px;
  border-radius: 13px;
  font-family:'din-pro-light';
  text-transform: uppercase
}
.intro-variation{
  margin-top: 75px;
}

/* Style the centered button */
.skip-button {
  background-color: transparent;
  color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color:#ffffff;
  font-size: 20px;
  border-radius: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom : 5px;
  text-align: center;
  margin-bottom: 20px;
  font-family:'din-pro-light';
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto
}

.title {
  font-size: 50px;
  color: #fff;
  font-family:'din-pro-light';
  text-transform: uppercase;
  margin-top: 100px;
}

.subtitle {
  font-size: 2.5em;
  color: #fff;
  font-family:'din-pro-light';
  text-transform: uppercase;
  text-align: center;
  margin-top: -10px;
  font-weight: normal;
}

.safranLogo{
  width: 200px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

}

.select{
  left: 150px;
  padding: 0 30px 0 10px;
  width :150px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background: #fff;
  font-family:'din-pro-light';
  margin-left: auto;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 18px;
  outline: none;
}

.select:disabled{
  opacity:0;
}

.select option{
  font-family:'din-pro-light';
  padding:50px 0;
}


/* Add responsive styles */
@media screen and (max-width: 768px) {
  .start-app-button {
    font-size: 16px;
    opacity: 0;
  }
}
